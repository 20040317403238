import React from "react"
import { Box } from "@material-ui/core"
import styled from "styled-components"

const Bar = styled.div`
  background-color: #edf1f5;
  height: 1.5em;
`

const BarButton = styled.div`
  display: inline-block;
  margin: .25em;
  background-color: ${props => props.color};
  border-radius: 50%;
  width: 1em;
  height: 1em;
`

const Toolbar = () => {
  return (
    <Bar>
      <BarButton color="#FF6057" />
      <BarButton color="#FFC12E" />
      <BarButton color="#28CA40" />
    </Bar>
  )
}

const Container = styled(Box)`
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #edf1f5;
  box-shadow: 0px 0px 50px 0px rgb(214, 214, 214);
`

const FakeBrowser = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <Toolbar />
      {children}
    </Container>
  )
}

export default FakeBrowser
