import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "../intl"
import sectionLayouts from "./section-layouts"
import Section from "./section"
import { filterLocalizedNodes } from "../localisation"

const Sections = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query SectionsQuery {
      allFile(
        filter: { relativePath: { glob: "sections/*" } }
        sort: { fields: name }
      ) {
        nodes {
          id
          childMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              title
              name
              layout
              videoMp4
              videoWebm
            }
          }
          name
        }
      }
    }
  `)

  const localizedNodes = filterLocalizedNodes(intl.locale, data.allFile.nodes);

  return (
    <section>
      {localizedNodes.map(section => {
        const sectionData = section.childMarkdownRemark
        const SectionRenderer = sectionLayouts[sectionData.frontmatter.layout]
        return (
          <Section mt={2} mb={2} key={section.id}>
            <SectionRenderer
              content={sectionData.rawMarkdownBody}
              data={sectionData.frontmatter}
            />
          </Section>
        )
      })}
    </section>
  )
}

export default Sections
