import React from "react"
import { Container } from '@material-ui/core';
import BasePage from "../components/base-page"
import HomeNavigation from "../components/home-navigation";
import Hero from "../components/hero";
import Sections from "../components/sections";
import Footer from "../components/footer";


const IndexPage = (props) => {
  return (
    <BasePage {...props}>
      <HomeNavigation />
      <Hero />
      <Container maxWidth="lg">
        <Sections />
      </Container>
      <Footer />
    </BasePage>
  );
}

export default IndexPage
