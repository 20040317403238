import React from "react"
import { Grid, Card, CardContent, Typography, Box } from "@material-ui/core"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import FeaturesSource from "../../datasources/features";
import BlurredImage from "./blurred-image"
import AspectRatio from "../util/aspect-ratio"
import { transparentize } from "polished"

const FeatureCard = styled(Card)`
  box-shadow: 0px 0px 50px 0px rgb(214, 214, 214);
  height: 100%;
`

const FeatureIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
`

const FeatureIcon = ({ children }) => {
  return <FeatureIconContainer>{children}</FeatureIconContainer>
}

const FeatureImageContainer = styled.div`
  position: relative;
`

const FeatureImageIconContainer = styled.div`
  background: ${props => transparentize(0.6, props.theme.palette.dark.main)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FeatureImage = ({ children, icon }) => {
  return (
    <FeatureImageContainer>
      <AspectRatio ratio={10 / 4}>{children}</AspectRatio>
      <FeatureImageIconContainer>
        <FeatureIcon>{icon}</FeatureIcon>
      </FeatureImageIconContainer>
    </FeatureImageContainer>
  )
}

const StyledHeadingTypography = styled(Typography)`
  font-weight: 600;
  text-align: center;
`

const HeadingRenderer = props => {
  return (
    <header>
      <StyledHeadingTypography variant="h6" component="h3">
        {props.children}
      </StyledHeadingTypography>
    </header>
  )
}

const ParagraphRenderer = props => <Box m={0} component="p" {...props} />

const renderers = { heading: HeadingRenderer, paragraph: ParagraphRenderer }

const Feature = ({ content, image, icon }) => {
  return (
    <FeatureCard textAlign="center" color="paper">
      <FeatureImage icon={icon}>{image}</FeatureImage>
      <CardContent component="article">
          <ReactMarkdown source={content} renderers={renderers} />
      </CardContent>
    </FeatureCard>
  )
}

const FeaturesList = () => {
  const Icon = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.palette.secondary.main};
    mask: url(${props => props.src}) no-repeat center;
  `

  return (
    <Grid container spacing={3} justify="center" component="section">
      <FeaturesSource>
        {({ nodes }) =>
          nodes.map(node => (
            <Grid item xs={6} lg={3} key={node.id}>
              <Feature
                icon={
                  <Icon
                    src={node.childMarkdownRemark.frontmatter.icon.publicURL}
                  />
                }
                image={
                  <BlurredImage
                    fluid={
                      node.childMarkdownRemark.frontmatter.image.childImageSharp
                        .fluid
                    }
                    blur={2}
                  />
                }
                content={node.childMarkdownRemark.rawMarkdownBody}
              />
            </Grid>
          ))
        }
      </FeaturesSource>
    </Grid>
  )
}

export default FeaturesList
