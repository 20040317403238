import React from "react"
import { Typography, Container, Grid, Button, Hidden } from "@material-ui/core"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "../../intl";
import Video from "./video"
import HeroBackground from "./hero-background"
import ReactMarkdown from "react-markdown";
import { filterLocalizedNodes } from "../../localisation";
import AspectRatio from "../util/aspect-ratio"

const StyledVideo = styled(Video)`
  width: 100%;
  height: 100%;
`

const NavigationOffset = styled.div`
  ${props => props.theme.mixins.toolbar}
`

const VideoContainer = styled(AspectRatio)`
  border-radius: 5px;
  background-color: black;
  overflow: hidden;
`

const StyledContainer = styled(Container)`
  height: 100%;
`

const StyledGrid = styled(Grid)`
  height: 100%;
`;

const renderers = {
  heading: (props) => (
    <Grid item>
      <Typography variant="h3" component="h1">
        { props.children }
      </Typography>
    </Grid>
  ),
  paragraph: (props) => (
    <Grid item>
      <Typography variant="subtitle1" component="p">
        { props.children }
      </Typography>
    </Grid>
  )
};

const Hero = ({ className }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query HeroQuery {
      allFile(
        sort: { fields: name }
        filter: { relativePath: { glob: "hero*.md" }, childMarkdownRemark: {} }
      ) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              video
            }
            rawMarkdownBody
          }
          name
        }
      }
    }
  `);

  const localized = filterLocalizedNodes(intl.locale, data.allFile.nodes);

  const node = localized[0];

  return (
    <HeroBackground className={className}>
      <NavigationOffset />
      <StyledContainer maxWidth="lg">
        <StyledGrid container spacing={3} justify="center">
          <Grid container item xs={12} md={6} alignItems="center" spacing={3}>
            <ReactMarkdown source={node.childMarkdownRemark.rawMarkdownBody} renderers={renderers} />
            <Hidden smDown>
              <Grid item>
                <Button color="secondary" variant="outlined" href={intl.getPagePath("demo")}>
                  { intl.formatMessage({ id: "hero_demo_button_msg" }) }
                </Button>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoContainer ratio={16 / 9}>
              <StyledVideo video={node.childMarkdownRemark.frontmatter.video} />
            </VideoContainer>
          </Grid>
        </StyledGrid>
      </StyledContainer>
      <NavigationOffset />
    </HeroBackground>
  )
}

export default Hero
