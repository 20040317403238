import React from "react"
import { Typography, Grid, Box } from "@material-ui/core"
import ReactMarkdown from "react-markdown";
import Underlined from "../underlined-typography";
import FeaturesList from "../features-list" 

const renderers = {
  heading: props => (
    <Box mb={3}>
      <Underlined variant="h2">{props.children}</Underlined>
    </Box>
  ),
  paragraph: props => <Box mb={3}><Typography variant="body1">{props.children}</Typography></Box>,
}

const Features = ({ content }) => {
  return (
    <Grid container item xs={12} justify="center">
      <ReactMarkdown source={content} renderers={renderers} />
      <FeaturesList />
    </Grid>
  )
}

export default Features
