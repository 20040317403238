import React from "react";
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import Underlined from "../underlined-typography";
import DemoVideoPlayer from "../demo-video-player";
import FakeBrowser from "../fake-browser";


const HeadingRenderer = (props) => (
  <Box mb={3}>
    <Underlined variant="h3" component="h2">{props.children}</Underlined>
  </Box>
);

const ParagraphRenderer = (props) => (
  <Typography variant="body1">{props.children}</Typography>
);

const renderers = {
  heading: HeadingRenderer,
  paragraph: ParagraphRenderer
}

const VideoLayout = ({ videoPosition, data, content }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  const direction = videoPosition === "left" && !isSmall ? "row-reverse" : "row";
  return (
    <Box mt={5}>
      <Grid container spacing={3} direction={direction}>
        <Grid item md={6} container>
          <Box component="article" mt={5}>
            <ReactMarkdown source={content} renderers={renderers} />
          </Box>
        </Grid>
        <Grid item md={6}>
          <FakeBrowser>
            <DemoVideoPlayer webm={data.videoWebm} mp4={data.videoMp4} />
          </FakeBrowser>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VideoLayout;