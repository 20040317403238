import React from "react";
import styled from "styled-components";
import Image from "gatsby-image";

const BlurredContainer = styled.div`
  position: relative;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(${props => props.blur}px);
`;

const BlurredImage = ({ blur, className, ...props }) => {
  return (
    <BlurredContainer>
      <Image {...props} />
      <Blur blur={blur} className={className} />
    </BlurredContainer>
  )
}

export default BlurredImage;