import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import BackgroundImage from 'gatsby-background-image'

const StyledBox = styled(Box)`
  position: relative;
  color: ${props => props.theme.palette.getContrastText("#050210")};
  background-color: black;
  ${props => props.theme.breakpoints.down('sm')} {
    height: 100vh;
    overflow: hidden;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  display: flex; 
  min-height: 100vh;
  ${props => props.theme.breakpoints.up("md")} {
    min-height: 50vh;
  }
`;

const HeroBackground = ({ className, children }) => {
  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("xs"));
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledBox>
      <StyledBackgroundImage className={className} fluid={data.file.childImageSharp.fluid} alt="inSync Background" isBig={isBig}>
        <Content>{children}</Content>
      </StyledBackgroundImage>
    </StyledBox>
  )
};

export default HeroBackground;