import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "../intl";

const FeaturesSource = ({ children }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query FeaturesListQuery {
      allFile(filter: { relativePath: { glob: "features/*" } }, sort: {fields: name}) {
        nodes {
          id
          name
          childMarkdownRemark {
            id
            frontmatter {
              name
              icon {
                publicURL
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 160) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            rawMarkdownBody
          }
        }
      }
    }
  `)

  const filtered = data.allFile.nodes.filter(node => {
    const [, locale] = node.name.split(".");

    return locale === intl.locale;
  });

  return children({
    ...data.allFile,
    nodes: filtered
  });
};

export default FeaturesSource;