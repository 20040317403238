export const filterLocalizedNodes = (locale, nodes) =>
  Object.values(nodes.reduce((acc, curr) => {
    const [filename, nodeLocale] = curr.name.split(".");
    if (
      locale === nodeLocale
    ) {
      acc[filename] = curr
    }
    return acc
  }, {}))
