import VideoRight from "./video-right";
import VideoLeft from "./video-left";
import Features from "./features";

const mappings = {
  "video-right": VideoRight,
  "video-left": VideoLeft,
  "features": Features
}

export default mappings;