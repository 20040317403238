import React from "react";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

const StyledSection = styled(Box)`
  position: relative;
  display: flex;
  min-height: ${props => props.isBig ? "50vh" : "auto"};
  width: 100%;
  align-items: center;
`;

const Section = (props) => {
  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("xs"));

  return <StyledSection {...props} isBig={isBig} component="article" />
};

export default Section;