import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navigation from "./navigation";

const StyledNavigation = styled(Navigation)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.didScroll ? props.theme.palette.dark.main : "transparent"};
  box-shadow: none;
`

const HomeNavigation = (props) => {
  const [didScroll, setdidScroll] = useState(false)

  useEffect(() => {
    const didScrollListener = () => {
      const newdidScroll = window.scrollY > 0
      newdidScroll !== didScroll && setdidScroll(window.scrollY > 0)
    }
    window.addEventListener("scroll", didScrollListener)

    return () => {
      window.removeEventListener("scroll", didScrollListener)
    }
  }, [didScroll])

  return <StyledNavigation didScroll={didScroll} />
}

export default HomeNavigation;